<template>
  <div class="cont">
    <div class="news_list">
      <div class="top_title" @click="changeIndex(0,'/team')">
        <h4>概况信息</h4>
      </div>
      <ul class="zczx">
        <li :class="activeIndex==item.id?'cur':''" v-for="item in datalist" @click="changeIndex(item.id,item.url)">
          {{item.name}}
        </li>
      </ul>

    </div>
  </div>

</template>

<script>
  export default {
    props: {
      cindex: {
        type: Number,
        default: 0
      },
    },
    data() {
      return {
         datalist:[
           {
             name:'特聘专家',
             id:1,
             url:'/teamExpert'
           },
           {
             name:'科创机构',
             id:2,
             url:'/organList'
           },
           {
             name:'创新团队',
             id:3,
              url:'/teamList'
           },
           {
             name:'园区企业',
             id:4,
             url:'/parkList'
           }
         ],
         activeIndex:'',
         url:'',
      }
    },
    watch:{
      activeIndex(val,old){
        if(val!=old){
          this.activeIndex = val
        }
      }
    },
    mounted() {
      this.activeIndex = this.cindex
    },
    methods: {
      //切换目录
      changeIndex(id,url){
       this.$router.push({
         path: url
       });
      }
    }
  }
</script>

<style lang="less" scoped>
  .news_list {
    width: 200px;
    overflow: hidden;
    float: left;

    .top_title {
      width: 100%;
      height: 54px;
      background: url(../../../assets/images/title.png) no-repeat;
      text-align: center;
      cursor: pointer;

      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 54px;
      }
    }

    .zczx {
      background: #fff;

    .cur{
      color: #00A0E9;
    }

      li {
          font-size: 16px;
          color: #333;
          background: #eee;
          display: block;
          width: 100%;
          height: 40px;
          line-height: 40px;
          margin-bottom: 2px;
          text-align: center;
          padding-left: 0;
          border: none;
          position: relative;
          cursor: pointer;
      }
    }
  }
</style>
