<template>
  <div class="bodycont">

    <comHeader />
   <!-- <div class="main">
      <navTitle :navData='navData' />
    </div> -->
    <!-- 成果列表展示页面 -->
    <div class="pcont">
      <div class="rightcont">
        <infonavtab :cindex='0' />
      </div>
      <div class="leftcont">
        <!-- <div class="search">
          <div class="demo-input-suffix">
            关键词：
             <el-input style="width: 200px;"  v-model="form.name"></el-input>
          </div>
          <div class="demo-input-suffix">
           <el-button type="primary">搜索</el-button>
          </div>
        </div> -->
        <div class="site_e">
          <div class="box">
            <div class="title">
              <p>分布</p>
              <p>全国各省市区</p>
            </div>
            <!-- <div class="icon_img">
              <img src="../../assets/images/index/icon1.png" />
            </div> -->
          </div>
          <div class="box">
            <div class="title">
              <p>行业</p>
              <p>覆盖国民经济主要行业</p>
            </div>
            <!-- <div class="icon_img">
              <img src="../../assets/images/index/icon2.png" />
            </div> -->
          </div>
          <div class="box">
            <div class="title">
              <p>领域</p>
              <p>覆盖多个主要领域</p>
            </div>
            <!-- <div class="icon_img">
              <img src="../../assets/images/index/icon2.png" />
            </div> -->
          </div>
          <div class="box">
            <div class="title">
            <p>性别</p>
            <p>{{sexValue}}%为男性,{{sexValue1}}%为女性</p>
            </div>
           <!-- <div class="icon_img">
              <img src="../../assets/images/index/icon3.png" />
            </div> -->
          </div>
         <!-- <div class="box">
            <div class="title">
              <p>职称</p>
              <p>50%为副高级以上</p>
            </div>
          </div> -->
         <!-- <div class="box">
            <div class="title">
            <p>单位</p>
            <p>30%为高校和研究院所</p>
            </div>
          </div> -->
        </div>
        <div class="totaldiv">
          <div id="myChart3" style="width: 50%;height: 300px;"></div>
          <div id="myChart4" style="width: 50%;height: 300px;"></div>
        <div id="myChart1" style="width: 50%;height: 300px;"></div>
        <div id="myChart2" style="width: 50%;height: 300px;"></div>
        
        <div id="myChart" style="width: 80%;height: 300px;"></div>
        </div>
      </div>


    </div>

    <bNav />
  </div>

</template>

<script>
  import comHeader from '@/components/header.vue'
  import bNav from '@/components/bottomNav.vue'
  import classifyType from '@/components/classify.vue'
  import navTitle from '@/components/navtitle.vue'
  import infonavtab from './components/navtab.vue'
  import api from '@/config/api'
  import {
    getRequst,postRequest
  } from '@/api/index'
  export default {
    components: {
      comHeader,
      bNav,
      classifyType,
      navTitle,
      infonavtab
    },
    data() {
      return {
        form: {},
        cindex: 1,
        sexValue:'',
        sexValue1:'',

      }
    },
    created() {

    },
    mounted() {
      this.getList()



    },
    methods: {
      //获取列表
      getList() {
        postRequest(api.mhEntryObject, {}).then(res => {
          console.log(res.map)
           this.drawLine(res.map);
           this.drawLine1(res.map1);
           this.drawLine2(res.map2);
           this.drawLine3(res.map3);
           this.drawLine4(res.map4);
        })

      },
      drawLine(obj) {
        let dataX = []
        let dataY= []
        for (let key in obj) {
           dataX.push(key)
           dataY.push(obj[key])
        }
        console.log(dataX,dataY)

        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById('myChart'));
        // 绘制图表
        myChart.setOption({
          title: {
            text: '年龄结构'
          },
          tooltip: {},
          xAxis: {
            data: dataX,
            axisLabel: {
               inside: true,
               color: '#fff'
             },
             axisTick: {
               show: false
             },
             axisLine: {
               show: false
             },
             axisLabel: {
                   show: true, // 是否显示刻度标签，默认显示
                   interval: 0, // 坐标轴刻度标签的显示间隔，在类目轴中有效；默认会采用标签不重叠的策略间隔显示标签；可以设置成0强制显示所有标签；如果设置为1，表示『隔一个标签显示一个标签』，如果值为2，表示隔两个标签显示一个标签，以此类推。
                   rotate: -60, // 刻度标签旋转的角度，在类目轴的类目标签显示不下的时候可以通过旋转防止标签之间重叠；旋转的角度从-90度到90度
                   inside: false, // 刻度标签是否朝内，默认朝外
                   margin: 6, // 刻度标签与轴线之间的距离
            },
             z: 10
          },
          yAxis: {
            axisLine: {
              show: false
            },
            axisTick: {
              show: false
            },
            axisLabel: {
              color: '#999'
            }
          },
          series: [{
            name: '数量',
            type: 'bar',
            data: dataY,

            itemStyle:{
                   normal: {
                     color: function (colors) {
                       var colorList = [
                         '#fc8251',
                         '#5470c6',
                         '#91cd77',
                         '#f9c956',
                         '#75bedc'
                       ];
                       return colorList[colors.dataIndex];
                     }
                   },
                 }
          }]
        });
      },
      drawLine1(obj) {
        let dataX = []
        let max = 0
        let sex = ''
        let sex1 = ''
        for (let key in obj) {
          if(key=='男'){
            sex = obj[key]
          }
          if(key=='女'){
            sex1 = obj[key]
          }
          dataX.push(
            {
              value:obj[key],
              name:key
            }
          )
        }
        dataX.forEach(item=>{
          max = max + Number(item.value)
        })
        this.sexValue= (Number(sex)/max*100).toFixed(0)
        this.sexValue1 = (Number(sex1)/max*100).toFixed(0)

        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById('myChart1'));
        // 绘制图表
        var option;

        option = {
          title: {
            text: '性别统计'
          },
          tooltip: {
              trigger: 'item'
            },
          series: [
            {
              type: 'pie',
              data: dataX,
              itemStyle:{
                     normal: {
                       color: function (colors) {
                         var colorList = [
                           '#fc8251',
                           '#5470c6',
                           '#91cd77',
                           '#ef6567',
                           '#f9c956',
                           '#75bedc'
                         ];
                         return colorList[colors.dataIndex];
                       }
                     },
                   }
            },

          ]

        };

        option && myChart.setOption(option);
      },


      drawLine2(obj) {
        let dataX = []
        let name = ''
        for (let key in obj) {
          if(key=='entryDetailTeamNum'){
            name = '团队数量'
          }
          if(key=='organizationNum'){
            name = '机构数量'
          }
          if(key=='entryDetailExpertsNum'){
            name = '专家数量'
          }
          dataX.push(
            {
              value:obj[key],
              name:name
            }
          )
        }
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById('myChart2'));
        // 绘制图表
        var option;

        option = {
          title: {
            text: '数量统计'
          },
          tooltip: {
              trigger: 'item'
            },
          series: [
            {
              type: 'pie',
              data: dataX,
              itemStyle:{
                     normal: {
                       color: function (colors) {
                         var colorList = [
                           '#fc8251',
                           '#5470c6',
                           '#91cd77',
                           '#ef6567',
                         ];
                         return colorList[colors.dataIndex];
                       }
                     },
                   }
            },

          ]

        };

        option && myChart.setOption(option);
      },

      drawLine3(obj) {
        let dataX = []
        for (let key in obj) {
          dataX.push(
            {
              value:obj[key],
              name:key
            }
          )
        }
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById('myChart3'));
        // 绘制图表
        var option;

        option = {
          title: {
            text: '应用领域统计'
          },
          tooltip: {
              trigger: 'item'
            },
          series: [
            {
              type: 'pie',
              data: dataX,
              itemStyle:{
                     normal: {
                       color: function (colors) {
                         var colorList = [
                           '#fc8251',
                           '#5470c6',
                           '#91cd77',
                           '#00c8a6',
                           '#ef6567',
                           '#f9c956',
                           '#75bedc',
                           '#00ca00',

                         ];
                         return colorList[colors.dataIndex];
                       }
                     },
                   }
            },

          ]

        };

        option && myChart.setOption(option);
      },


      //学科领域

      drawLine4(obj) {
        let dataX = []
        for (let key in obj) {
          dataX.push(
            {
              value:obj[key],
              name:key
            }
          )
        }
        // 基于准备好的dom，初始化echarts实例
        var myChart = this.$echarts.init(document.getElementById('myChart4'));
        // 绘制图表
        var option;

        option = {
          title: {
            text: '学科领域统计'
          },
          tooltip: {
              trigger: 'item'
            },
          series: [
            {
              type: 'pie',
              data: dataX,
              itemStyle:{
                     normal: {
                       color: function (colors) {
                         var colorList = [
                           '#fc8251',
                           '#5470c6',
                           '#91cd77',
                           '#00c8a6',
                           '#ef6567',
                           '#f9c956',
                           '#75bedc',
                           '#00ca00',

                         ];
                         return colorList[colors.dataIndex];
                       }
                     },
                   }
            },

          ]

        };

        option && myChart.setOption(option);
      },


      //查看详情
      tourl(data) {
        this.$router.push({
          path: '/tecDetails',
          query: {},
        });
      },
      //切换分类筛选
      changindex(index) {
        this.cindex = index
      }
    }
  }
</script>

<style lang="less" scoped>
  .bodycont {
    // background: url('../../assets/images/homebg1.png') repeat-x;
    width: 100%;
  }

  .main {
    width: 1180px;
    margin: 0px auto 0;
  }

  .site_h2 {
    height: 80px;
    width: 350px;
    margin: 0 auto;
    line-height: 80px;
    text-align: center;

    span {
      font-size: 24px;
      padding: 0 15px;
      background: #fff;
    }
  }

  .pages {
    width: 1180px;
    margin: 20px auto;
    text-align: center;
  }

  .cont1 {
    min-height: 300px;
  }

  .pcont {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
    width:1190px;
   margin: 20px auto;
    font-size: 14px;
    min-height: 70vh;

    .rightcont {
      width: 200px;
    }

    .leftcont {
      width: 80%;
    // box-shadow: 0px 5px 14px 0 #ddd;
      .lcont {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }
    }

    .continfo {
      display: flex;
      justify-content: space-between;
      width: 49%;
      margin-bottom: 20px;
      position: relative;
      background-color: #f0f8fc;
      padding: 20px 20px 40px;
      border-radius: 6px;

      img {
        width: 150px;
        height: 150px;
        border-radius: 50%;
      }

      .infobox {
        margin-left: 10px;
        margin-top: 10px;

        h3 {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 20px;
        }

        p {
          font-size: 14px;
          color: #666;
          margin-bottom: 10px;

          span {
            color: #000;
          }
        }

        .infos {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
          line-height: 24px;
        }
      }
    }
  }

  .cinfo {
    border: 1px solid #00428E;
    padding: 6px;
    color: #00428E;
    text-align: center;
    border-radius: 4px;
    position: absolute;
    right: 20px;
    bottom: 20px;
  }

  .search {
    display: flex;
     justify-content: flex-start; 
    margin-bottom: 20px;

    .demo-input-suffix {
      margin-right: 20px;
    }
  }


  .news_list {
    width: 150px;
    overflow: hidden;
    float: left;

    .top_title {
      width: 100%;
      height: 54px;
      background: url(../../assets/images/title.png) no-repeat;
      text-align: center;

      h4 {
        color: #fff;
        font-size: 18px;
        line-height: 54px;
      }
    }

    .zczx {
      padding: 10px 0px;
      background: #fff;

      .cur {
        background-color: #00428E;
        color: #fff;
      }

      li {
        font-size: 16px;
        color: #333;
        background: #eee;
        display: block;
        width: 100%;
        height: 40px;
        line-height: 40px;
        margin-bottom: 2px;
        text-align: center;
        padding-left: 0;
        border: none;
        position: relative;
        cursor: pointer;
      }
    }
  }
  .totaldiv{
    display: flex;
    justify-content:center;
    flex-wrap: wrap;
    min-height:400px;
  }



  .site_e {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 20px auto;
    flex-wrap: wrap;
    align-items: center;

    .box {
     width: 24%;
     text-align: center;
     background-color: #00428E;
     padding-bottom: 20px;
     overflow: hidden;
     box-shadow: 0 0px 5px #ddd;
     border-radius: 10px;
      .icon_img {
        img {
          width: 30px;
          height: 30px;
        }
      }

      .title {
        font-size: 16px;
        color:#fff;
        margin-top: 20px;
      }
    }
    .box p:first-child  {
      font-size: 16px;
      margin: 10px auto 10px;
      border:1px solid #fff;
      width: 80px;
      border-radius: 5px;
      padding: 4px;
    }
    .box p:last-child  {
      font-size: 14px;
      margin: 20px auto 0px;
    }

  }
</style>
